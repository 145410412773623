.invoice-wrapper {
	position: relative;
	background: #fff;
	padding: 40px 35px;
	box-shadow: 0 0 17px 0 rgba(16, 40, 73, 0.09);
}

.row {
	position: relative;
}

.row__remove {
	display: flex;
	padding: 0;
	position: absolute;
	top: 10px;
	right: -20px;
	opacity: 0;
	transition: opacity 0.2s ease-in-out;

	.row:hover & {
		opacity: 1;
	}
}

.link {
	display: flex;
	background: transparent;
	border: 0;
	color: #428bca;

	&:hover,
	&:focus {
		text-decoration: underline;
	}

	&:focus {
		outline: 1px dotted #66afe9;
		opacity: 1;
	}
}

.icon {
	display: inline-block;
	position: relative;
	width: 16px;
	height: 16px;
	border: 0;
	border-radius: 50%;
}

.icon-remove {
	&::before,
	&::after {
		content: "";
		position: absolute;
		width: 2px;
		height: 10px;
		left: 7px;
		top: 3px;
		background-color: #fff;
	}

	&::before {
		transform: rotate(45deg);
	}

	&::after {
		transform: rotate(-45deg);
	}
}

.bg-red {
	background-color: #f03434;
}
