.buttons-section {
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
}

.add-user-modal > .ant-modal-content {
	padding: 15px 40px;
}

.add-user-modal > .ant-modal-content > .ant-modal-close {
	margin-right: 20px;
}
