.main-wrapper {
  height: 100%;
}

.header {
  display: flex;
  align-content: center;
  background-color: #0a0523;

  &__inner {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
  }

  &__logo {
    display: flex;
  }

  .bell-icon {
    font-size: 1.2rem;
    color: #FFFFFF;
    cursor: pointer;
  }

  .notifications-user {
    display: flex;
    align-items: center;
  }
}
