.wrapper {
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	position: fixed;
	z-index: 100;
	background: white;
}

.logo_container {
	width: 94px;
	height: 94px;
	background-size: contain;
	display: flex;
	justify-content: center;
	position: absolute;
	--angle: 0deg;
	border-radius: 11px;
	background: linear-gradient(
		var(--angle),
		#1f73f6 -6.48%,
		#b154fb 23.61%,
		#00e4a6 48.79%,
		#4cffe9 76.42%,
		#f2c100 111.42%
	);
	background-origin: border-box;
	background-clip: content-box, border-box;
	-webkit-animation: 5s rotate_background infinite;
}

@keyframes rotate_background {
	to {
		--angle: 720deg;
	}
}

@keyframes resize {
	to {
		min-width: 900px;
		min-height: 900px;
		filter: blur(1000px);
	}
}

@keyframes resizeMobile {
	to {
		min-width: 900px;
		min-height: 900px;
		filter: blur(100px);
	}
}

@keyframes white_circle {
	100% {
		width: 200vw;
		height: 200vw;
		opacity: 0.9;
		overflow: hidden;
	}
}

/*don't delete this*/
@property --angle {
	syntax: "<angle>";
	initial-value: 0deg;
	inherits: false;
}
/**/
